import { get, writable } from "svelte/store";
import { createActor } from "../lib/actor";
import { AuthClient } from "@dfinity/auth-client";
import { CryptoService } from "../lib/crypto";
import "./notifications";
import "../lib/sleep";
import { navigateTo } from "svelte-router-spa";
export const auth = writable({
    state: "initializing-auth",
});
async function initAuth() {
    const client = await AuthClient.create();
    if (await client.isAuthenticated()) {
        authenticate(client);
    }
    else {
        auth.update(() => ({
            state: "anonymous",
            actor: createActor(),
            client,
        }));
    }
}
initAuth();
export function login() {
    const currentAuth = get(auth);
    if (currentAuth.state === "anonymous") {
        currentAuth.client.login({
            maxTimeToLive: BigInt(1800) * BigInt(1000000000),
            identityProvider: process.env.DFX_NETWORK === "ic"
                ? "https://identity.ic0.app/#authorize"
                : `http://${process.env.INTERNET_IDENTITY_CANISTER_ID}.localhost:8000/#authorize`,
            // `http://localhost:8000?canisterId=${process.env.INTERNET_IDENTITY_CANISTER_ID}#authorize`,
            onSuccess: () => authenticate(currentAuth.client),
        });
    }
}
export async function logout() {
    const currentAuth = get(auth);
    if (currentAuth.state === "initialized") {
        await currentAuth.client.logout();
        auth.update(() => ({
            state: "anonymous",
            actor: createActor(),
            client: currentAuth.client,
        }));
        navigateTo("/");
    }
}
export async function authenticate(client) {
    handleSessionTimeout();
    try {
        const actor = createActor({
            agentOptions: {
                identity: client.getIdentity(),
            },
        });
        auth.update(() => ({
            state: "initializing-crypto",
            actor,
            client,
        }));
        const cryptoService = new CryptoService(actor);
        auth.update(() => ({
            state: "initialized",
            actor,
            client,
            crypto: cryptoService,
        }));
    }
    catch (e) {
        auth.update(() => ({
            state: "error",
            error: e.message || "An error occurred",
        }));
    }
}
// set a timer when the II session will expire and log the user out
function handleSessionTimeout() {
    // upon login the localstorage items may not be set, wait for next tick
    setTimeout(() => {
        try {
            const delegation = JSON.parse(window.localStorage.getItem("ic-delegation"));
            const expirationTimeMs = Number.parseInt(delegation.delegations[0].delegation.expiration, 16) /
                1000000;
            setTimeout(() => {
                logout();
            }, expirationTimeMs - Date.now());
        }
        catch (error) {
            console.error("Could not handle delegation expiry.", error);
        }
    });
}
