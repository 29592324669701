import { writable } from 'svelte/store';
let nextId = 0;
export const notifications = writable([]);
export function addNotification(notification, timeout = 2000) {
    const id = nextId++;
    notifications.update(($n) => [...$n, Object.assign(Object.assign({}, notification), { id })]);
    setTimeout(() => {
        notifications.update(($n) => $n.filter((n) => n.id != id));
    }, timeout);
}
export function showError(e, message) {
    addNotification({ type: 'error', message });
    console.error(e);
    console.error(e.stack);
    throw e;
}
