export const idlFactory = ({ IDL }) => {
  const PrincipalEntry = IDL.Record({
    'name' : IDL.Opt(IDL.Text),
    'when' : IDL.Opt(IDL.Nat64),
  });
  const HistoryEntry = IDL.Record({
    'action' : IDL.Text,
    'user' : IDL.Opt(IDL.Text),
    'when' : IDL.Opt(IDL.Nat64),
    'created_at' : IDL.Nat64,
  });
  const EncryptedNote = IDL.Record({
    'id' : IDL.Nat,
    'updated_at' : IDL.Nat64,
    'encrypted_text' : IDL.Text,
    'owner' : IDL.Text,
    'data' : IDL.Text,
    'locked' : IDL.Bool,
    'history' : IDL.Vec(HistoryEntry),
    'created_at' : IDL.Nat64,
    'users' : IDL.Vec(PrincipalEntry),
  });
  const anon_class_15_1 = IDL.Service({
    'add_user' : IDL.Func([IDL.Nat, PrincipalEntry], [], []),
    'create_note' : IDL.Func([], [IDL.Nat], []),
    'delete_note' : IDL.Func([IDL.Nat], [], []),
    'encrypted_symmetric_key_for_note' : IDL.Func(
        [IDL.Nat, IDL.Vec(IDL.Nat8)],
        [IDL.Text],
        [],
      ),
    'get_notes' : IDL.Func([], [IDL.Vec(EncryptedNote)], []),
    'remove_user' : IDL.Func([IDL.Nat, IDL.Opt(IDL.Text)], [], []),
    'symmetric_key_verification_key_for_note' : IDL.Func([], [IDL.Text], []),
    'update_note' : IDL.Func([IDL.Nat, IDL.Text, IDL.Text], [], []),
    'whoami' : IDL.Func([], [IDL.Text], []),
  });
  return anon_class_15_1;
};
export const init = ({ IDL }) => { return []; };
