import { Actor, HttpAgent, } from "@dfinity/agent";
import { ENCRYPTED_NOTES_CANISTER_ID } from "./backend";
import { idlFactory } from "./backend";
export function createActor(_options) {
    const hostOptions = {
        host: process.env.DFX_NETWORK === "ic"
            ? `https://${ENCRYPTED_NOTES_CANISTER_ID}.ic0.app`
            : "http://localhost:8000",
    };
    let options = _options;
    if (!options) {
        options = {
            agentOptions: hostOptions,
        };
    }
    else if (!options.agentOptions) {
        options.agentOptions = hostOptions;
    }
    else {
        options.agentOptions.host = hostOptions.host;
    }
    const agent = new HttpAgent(Object.assign({}, options.agentOptions));
    // Fetch root key for certificate validation during development
    if (process.env.NODE_ENV !== "production") {
        console.log("Dev environment - fetching root key...");
        agent.fetchRootKey().catch((err) => {
            console.warn("Unable to fetch root key. Check to ensure that your local replica is running");
            console.error(err);
        });
    }
    // Creates an actor with using the candid interface and the HttpAgent
    return Actor.createActor(idlFactory, Object.assign({ agent, canisterId: ENCRYPTED_NOTES_CANISTER_ID }, options === null || options === void 0 ? void 0 : options.actorOptions));
}
