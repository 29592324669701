export function noteFromContent(content, tags, self_principal) {
    const title = extractTitle(content);
    const creationTime = Date.now();
    return {
        id: BigInt(0),
        title,
        content,
        tags,
        owner: self_principal.toString(),
        users: [],
        createdAt: creationTime,
        updatedAt: creationTime,
        locked: false,
        history: [],
    };
}
export async function serialize(note, cryptoService) {
    const data = {
        title: note.title,
        createdAt: note.createdAt,
        updatedAt: note.updatedAt,
        tags: note.tags,
    };
    const serializableNote = {
        content: note.content,
    };
    const encryptedNote = await cryptoService.encryptWithNoteKey(note.id, note.owner, JSON.stringify(serializableNote));
    return {
        id: note.id,
        encrypted_text: encryptedNote,
        data: JSON.stringify(data),
        owner: note.owner,
        users: note.users.map((user) => ({
            name: user.name ? [user.name] : [],
            when: user.when ? [user.when] : [],
        })),
        history: [],
        created_at: BigInt(note.createdAt * 1000000),
        updated_at: BigInt(note.updatedAt * 1000000),
        locked: note.locked,
    };
}
export async function deserialize(enote, cryptoService) {
    const serializedNote = await cryptoService.decryptWithNoteKey(enote.id, enote.owner, enote.encrypted_text);
    const deserializedNote = JSON.parse(serializedNote);
    const data = JSON.parse(enote.data);
    return Object.assign(Object.assign(Object.assign({ id: enote.id, owner: enote.owner, users: enote.users.map((user) => ({
            name: user.name[0] || null,
            when: user.when[0] || null,
        })) }, deserializedNote), data), { history: enote.history.map((entry) => ({
            action: entry.action,
            user: entry.user.length > 0 ? entry.user[0] : null,
            when: entry.when.length > 0 ? Number(entry.when[0] / BigInt(1000000)) : null,
            createdAt: Number(entry.created_at / BigInt(1000000)),
        })), createdAt: Number(enote.created_at / BigInt(1000000)), updatedAt: Number(enote.updated_at / BigInt(1000000)), locked: enote.locked });
}
export function summarize(note, maxLength = 50) {
    const div = document.createElement("div");
    div.innerHTML = note.content;
    let text = div.innerText;
    const title = extractTitleFromDomEl(div);
    if (title) {
        text = text.replace(title, "");
    }
    return text.slice(0, maxLength) + (text.length > maxLength ? "..." : "");
}
function extractTitleFromDomEl(el) {
    var _a;
    const title = el.querySelector("h1");
    if (title) {
        return title.innerText;
    }
    const blockElements = el.querySelectorAll("h1,h2,p,li");
    for (const el of blockElements) {
        if (((_a = el.innerText) === null || _a === void 0 ? void 0 : _a.trim().length) > 0) {
            return el.innerText.trim();
        }
    }
    return "";
}
export function extractTitle(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return extractTitleFromDomEl(div);
}
