import { writable } from 'svelte/store';
import { auth } from './auth';
let initialDraft = {
    content: '',
    tags: [],
};
try {
    const savedDraft = JSON.parse(localStorage.getItem('draft'));
    if ('content' in savedDraft && 'tags' in savedDraft) {
        initialDraft = savedDraft;
    }
}
catch (_a) { }
export const draft = writable(initialDraft);
draft.subscribe((draft) => {
    localStorage.setItem('draft', JSON.stringify(draft));
});
auth.subscribe(($auth) => {
    if ($auth.state === 'anonymous') {
        draft.set(initialDraft);
    }
});
